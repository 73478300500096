.card {
    height: 440px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-size: cover;
    background-position: center;
}

.title {
    font-weight: 900;
    color: var(--mantine-color-white);
    line-height: 1.2;
    font-size: 32px;
    margin-top: var(--mantine-spacing-xs);
    cursor: default;
}

.category {
    color: var(--mantine-color-white);
    opacity: 0.7;
    font-weight: 700;
    text-transform: uppercase;
    cursor: default;
}